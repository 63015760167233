import { useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { ReactComponent as Grid } from "../Assets/grid4.svg";
import {
  ChartBarIcon,
  ChatBubbleBottomCenterIcon,
  FingerPrintIcon,
  ScaleIcon,
  SparklesIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function Advantages() {
  const { t } = useTranslation();

  return (
    <div className="w-full relative flex overflow-hidden justify-center lg:px-10 px-5 lg:pb-20 pb-10  to-white lg:mt-20 pt-5 lg:pt-16">
      <div className="w-full opacity-5 absolute -top-1/4 ">
        <Grid />
      </div>

      <div className="w-full flex flex-col items-center  max-w-6xl">
        <h2 className="text-3xl  z-20 text-text lg:text-center  w-full  font-bold ">
          {t("allFeatures") + " "}
          {t("oneView")}
        </h2>

        <div className="grid lg:grid-cols-3 grid-cols-1 w-full lg:gap-10 gap-2 lg:mt-16 mt-8">
          <div className="flex">
            <AdvantagItem
              title={t("chatbot")}
              text={t("chatbotText")}
              icon={<ChatBubbleBottomCenterIcon strokeWidth="2" />}
            />
          </div>

          <div className="flex">
            <AdvantagItem
              title={t("productComparison")}
              text={t("productComparisonText")}
              icon={<ScaleIcon strokeWidth="2" />}
            />
          </div>

          <div className="flex w-full">
            <AdvantagItem
              title={t("corporateDesign")}
              text={t("corporateDesignText")}
              icon={<FingerPrintIcon strokeWidth="2" />}
            />
          </div>

          <div className="flex w-full">
            <AdvantagItem
              title={t("multimediaPresentation")}
              text={t("multimediaPresentationText")}
              icon={<VideoCameraIcon strokeWidth="2" />}
            />
          </div>

          <div className="flex w-full flex-stretch">
            <AdvantagItem
              title={t("aiContentCreation")}
              text={t("aiContentCreationText")}
              icon={<SparklesIcon strokeWidth="2" />}
            />
          </div>

          <div className="flex">
            <AdvantagItem
              title={t("liveTrackingFeedback")}
              text={t("liveTrackingFeedbackText")}
              icon={<ChartBarIcon strokeWidth="2" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function AdvantagItem({ icon, title, text, soon }) {
  const [showItem, setShowItem] = useState(false);
  return (
    <div className="lg:p-8 p-4 bg-lightgray shadow-lg shadow-[#f2f2f2] lg:bg-white w-full lg:h-80  flex flex-col lg:items-center relative rounded-lg">
      {soon && (
        <div className="w-28 h-8 font-semibold bg-[#616FDB] hidden lg:flex text-sm text-white items-center justify-center absolute -top-0 -right-5 rotate-12 rounded">
          coming soon
        </div>
      )}
      <div
        onClick={() => {
          setShowItem(!showItem);
        }}
        className="flex relative flex-row lg:flex-col justify-between items-center"
      >
        <div className="flex lg:flex-col items-center">
          <div className="lg:w-14 lg:h-14 w-12 h-12 lg:mb-5 aspect-square rounded-full  bg-primary flex justify-center items-center">
            <div className="w-6 text-text">{icon}</div>
          </div>

          <div>
            <h2 className="text-xl lg:ml-0 ml-5 text-text text-left lg:text-center font-bold ">
              {title}
            </h2>
            {soon && (
              <div className="ml-5 flex lg:hidden text-sm font-semibold text-primary">
                coming soon
              </div>
            )}
          </div>
        </div>

        <div
          className={`lg:w-14 lg:hidden opacity-70 lg:h-14 w-12 h-12 lg:mb-5  rounded-full transition-all  flex justify-center items-center ${
            showItem ? "-rotate-180" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#d8d3e3"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      <p
        className={`text-darkgray transition-all lg:text-center  text-lg lg:py-0 py-3 lg:text-base mt-3 ${
          showItem ? "flex" : "lg:flex hidden"
        }`}
      >
        {text}
      </p>
    </div>
  );
}

export default Advantages;
