import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as WomenWithPhone } from "../Assets/women_with_phone.svg";
import { ReactComponent as Chat1 } from "../Assets/chat1.svg";
import { ReactComponent as Chat2 } from "../Assets/chat2.svg";
import { ReactComponent as QR } from "../Assets/qr.svg";
import { motion, useScroll, useTransform } from "framer-motion";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function ChatSection() {
  const slidePhoneMockups = {
    offscreen: {
      y: 100,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const slideUp2 = {
    offscreen: {
      y: 80,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const { t } = useTranslation();

  return (
    <div className="bg-white justify-center lg:px-10 px-5 pb-10 lg:pt-0  w-full relative flex ">
      <div className="max-w-6xl flex items-start w-full flex-col items-center justify-between">
        {/* <h2 className="text-3xl w-full text-text lg:text-center font-bold ">
          {t("aiChatHeading")}
        </h2>
        <div className="text-darkgray mt-4 text-lg max-w-2xl lg:text-center">
          {t("howItWorksDescription")}
        </div> */}

        <div className="w-full lg:justify-between flex-col lg:flex-row  gap-5 flex lg:mt-20">
          <div className="flex flex-col flex-grow">
            <div className="lg:pr-20">
              <h2 className="text-4xl lg:mt-12 leading-[45px]  text-text font-extrabold ">
                {t("chatSectionHeading")}
              </h2>
              <p className="text-darkgray mt-3 text-lg">
                {t("chatSectionText")}
              </p>

              <div className="mt-10">
                <div className="flex gap-3 lg:pr-20 text-darkgray ">
                  <div className="w-6 h-6 flex mt-0.5 items-center justify-center text-white rounded-full bg-primary shrink-0 gap-2">
                    <CheckIcon className="w-3" strokeWidth={3} />
                  </div>
                  <div>
                    <h1 className="text-lg mb-1 text-dark font-semibold">
                      {t("chatSectionAdvantage1")}
                    </h1>
                    {/* Beantwortet allgemeine Fragen schnell und präzise – auch
                    außerhalb der Öffnungszeiten. */}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex gap-3 lg:pr-20 text-darkgray ">
                  <div className="w-6 h-6 flex mt-0.5 items-center justify-center text-white rounded-full bg-primary shrink-0 gap-2">
                    <CheckIcon className="w-3" strokeWidth={3} />
                  </div>
                  <div>
                    <h1 className="text-lg mb-1 text-dark font-semibold">
                      {t("chatSectionAdvantage2")}
                    </h1>
                    {/* Beantwortet allgemeine Fragen schnell und präzise – auch
                    außerhalb der Öffnungszeiten. */}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex gap-3 lg:pr-20 text-darkgray ">
                  <div className="w-6 h-6 flex mt-0.5 items-center justify-center text-white rounded-full bg-primary shrink-0 gap-2">
                    <CheckIcon className="w-3" strokeWidth={3} />
                  </div>
                  <div>
                    <h1 className="text-lg mb-1 text-dark font-semibold">
                      {t("chatSectionAdvantage3")}
                    </h1>
                    {/* Ohne konkrete Marken oder Preise zu nennen, unterstützt der
                    Chatbot Ihre Kunden bei der Kaufentscheidung – wie ein
                    digitaler Verkaufsberater. */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden relative w-full lg:mt-0 mt-5 lg:w-[900px] ">
            <WomenWithPhone />
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true, amount: 0.1 }}
              variants={slidePhoneMockups}
              className="lg:w-60 w-52 absolute bottom-28 lg:bottom-32 left-8 z-10"
            >
              <img
                src={require("../Assets/chat2.png")}
                className="object-cover w-full rounded-lg"
              />
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true, amount: 0.1 }}
              variants={slideUp2}
              className="lg:w-60 w-52 absolute bottom-8 right-8"
            >
              {/* <Chat1 /> */}
              <img
                src={require("../Assets/chat1.png")}
                className="object-cover w-full rounded-lg"
              />
            </motion.div>
          </div>
          {/* <div className="w-[450px] shrink-0 h-[420px] bg-dark p-8 rounded-lg overflow-hidden relative">
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true, amount: 0.1 }}
              variants={slidePhoneMockups}
              className="flex w-full justify-start pr-10"
            >
              <div className="rounded-lg border border-border font-semibold text-sm text-text  bg-white p-4 ">
                Hallo 👋 <br /> ich bin dein digitaler Verkaufsberater. Wie kann
                ich dir heute helfen?
              </div>
            </motion.div>

            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true, amount: 0.1 }}
              variants={slideUp2}
              className="flex w-full justify-start pl-20 mt-5"
            >
              <div className="rounded-lg font-semibold border border-primary text-sm bg-primary text-white p-4 ">
                Ich suche ein Geschenk für meine Mutter
              </div>
            </motion.div>
          </div> */}
        </div>

        {/* <div className="text-darkgray mt-4 text-lg max-w-3xl lg:text-center">
          {t("howItWorksDescription")}
        </div> */}

        {/* <div className="flex mt-10   lg:pt-10">
          <div className="lg:w-2/5 w-full shrink-0 ">
            <div className="rounded-2xl lg:rounded lg:border-0 border border-border lg:overflow-auto overflow-hidden lg:bg-transparent bg-lightgray lg:p-0 p-5 text-darkgray flex flex-col items-center lg:items-start">
              <div className="bg-white px-5 my-2 py-2 rounded-full inline-flex text-text font-semibold text-sm lg:hidden mb-5">
                {t("step1")}
              </div>
              <h1 className="font-bold text-text flex items-center text-xl mb-2">
                <div className="w-8 h-8 hidden lg:flex font-bold text-base rounded-full mr-2 items-center justify-center  bg-primary text-white">
                  1
                </div>
                {t("productsCreation")}
              </h1>
              <p className="lg:pr-10 text-center lg:text-start">
                {t("step1Description")}
              </p>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={slidePhoneMockups}
                className="w-full lg:absolute rounded border border-border -mb-10 lg:w-0 lg:h-0 mt-10"
              >
                <RFMockupCms />
              </motion.div>
            </div>

            <div className="rounded-2xl lg:rounded lg:border-0 border border-border mt-10 lg:overflow-auto overflow-hidden lg:bg-transparent bg-lightgray lg:p-0 p-5 text-darkgray flex flex-col items-center lg:items-start">
              <div className="bg-white px-5 my-2 py-2 rounded-full inline-flex text-text font-semibold text-sm lg:hidden mb-5">
                {t("step2")}
              </div>
              <h1 className="font-bold text-text flex items-center text-xl mb-2">
                <div className="w-8 h-8 hidden lg:flex font-bold text-base rounded-full mr-2 items-center justify-center  bg-primary text-white">
                  2
                </div>
                {t("qrCodes")}
              </h1>
              <p className="lg:pr-10 text-center lg:text-start">
                {t("step2Description")}
              </p>
              <div className="w-56 h-56 bg-primary rounded-2xl p-2.5  -mb-28 lg:hidden mt-10">
                <QR />
              </div>
            </div>

            <div className="rounded-2xl lg:rounded lg:border-0 border border-border mt-10 lg:overflow-auto overflow-hidden lg:bg-transparent bg-lightgray lg:p-0 p-5 text-darkgray flex flex-col items-center lg:items-start">
              <div className="bg-white px-5 my-2 py-2 rounded-full inline-flex text-text font-semibold text-sm lg:hidden mb-5">
                {t("step3")}
              </div>
              <h1 className="font-bold text-text flex items-center text-xl mb-2">
                <div className="w-8 h-8 hidden lg:flex font-bold text-base rounded-full mr-2 items-center justify-center  bg-primary text-white">
                  3
                </div>
                {t("consulting")}
              </h1>
              <p className="lg:pr-10 text-center lg:text-start">
                {t("step3Description")}
              </p>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={slidePhoneMockups}
                className="w-56 rounded lg:w-0 lg:h-0 lg:absolute  -mb-56  mt-10"
              >
                <RFMockupPhone />
              </motion.div>
            </div>
          </div>
          <div className="w-3/5 ml-0 relative p-0 pl-24 bg-white rounded-lg  flex items-center justify-center">
            <div className="w-full hidden lg:block  rounded-lg shadow shadow-xl">
              <RFMockupCms />

              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={slidePhoneMockups}
                className="absolute w-28 left-12 bottom-0"
              >
                <RFMockupPhone />
              </motion.div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
