import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import "../App.css";
import { ReactComponent as Hero1 } from "../Assets/hero1.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import {
  LifebuoyIcon,
  RocketLaunchIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

function Hero({ loading }) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [portalTarget, setPortalTarget] = useState(null);

  const { scrollY } = useScroll();
  const translateHeroY = useTransform(scrollY, [400, 800], [0, 250]);
  const translateHeroYmobile = useTransform(scrollY, [0, 550], [1, 1.3]);
  const translateHeroX = useTransform(scrollY, [520, 800], [0, 250]);
  const scaleHero = useTransform(scrollY, [520, 800], [1, 0.55]);
  const translateAnimBoxLeft = useTransform(scrollY, [220, 1500], [-80, -2000]);
  const translateAnimBoxTop = useTransform(scrollY, [220, 1500], [0, 1200]);
  const translateAnimBoxRight = useTransform(scrollY, [200, 1500], [100, 2000]);
  const translateAnimBoxRightSlow = useTransform(
    scrollY,
    [230, 2300],
    [0, 2000]
  );
  const opacityAnim = useTransform(scrollY, [350, 460], [1, 0]);
  const opacityAnimText = useTransform(scrollY, [630, 850], [0, 1]);
  const translateLeftCircleY = useTransform(scrollY, [0, 1000], [0, 500]);
  // const scrollLeftPhone = useTransform(scrollY, [800, 1100], [200, 0]);

  const translateAnimBoxRightSecond = useTransform(
    scrollY,
    [200, 1200],
    [0, 900]
  );

  const slideHeroimage = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    // Disable scrolling
    if (videoPlaying) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup to enable scrolling again when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [videoPlaying]);

  useEffect(() => {
    const portalDiv = document.createElement("div");
    portalDiv.id = "video-portal";
    document.body.appendChild(portalDiv);
    setPortalTarget(portalDiv);

    // Cleanup the created div when component unmounts
    return () => {
      document.body.removeChild(portalDiv);
    };
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const { t, i18n } = useTranslation();

  return (
    <div className="bg-gradient-to-tr from-white to-white lg:to-[#F2FBF8] justify-center lg:px-10   w-full lg:h-screen  relative flex  items-center ">
      <div className="max-w-6xl pt-10 flex items-center lg:flex-row flex-col w-full justify-between">
        <div className="flex-grow lg:p-0 p-5 pt-20 lg:pr-28 relative z-20">
          <h1 className="font-extrabold -mt-5 lg:-mt-10 font-open-sans mb-4 text-3xl lg:text-6xl max-w-2xl  text-text leading-[40px]  lg:leading-[70px]">
            {t("der")} <span className="text-primary"> {t("digital")}</span>{" "}
            {t("salesAssistent")}
          </h1>

          <p className="mt-2 text-lg lg:text-xl text-darkgray leading-[30px] lg:leading-[32px]">
            {/* Mit retailflow können Sie als Unternehmen, das erklärungsbedürftige
            Produkte im Ladengeschäft verkauft, eigenständig einen digitalen
            Verkaufsassistenten erstellen, der ihre Kundschaft per Smartphone
            über alle Produktdetails aufklärt und eine Kaufentscheidung
            vorantreibt. */}
            {t("heroSubtitle")}
          </p>
          <a
            className="bg-primary text-white hover:bg-secondary active:bg-active transition-all font-bold  flex inline-flex mt-14 text-base rounded-lg px-5 py-4 lg:py-3"
            target="_blank"
            href="https://calendly.com/christoph-v-trotha/-mudioo"
          >
            {t("bookCall")}
          </a>
        </div>
        <div className="lg:px-0 px-5">
          <motion.div
            initial={screenSize.width < 980 ? "onscreen" : "offscreen"}
            whileInView={!loading && "onscreen"}
            viewport={{ once: true, amount: 0.1 }}
            variants={slideHeroimage}
            className=" lg:h-[550px] lg:w-[450px] w-full h-96  mt-10 bg-dark z-30 shadow shadow-xl relative rounded-lg shadow shrink-0"
          >
            {/* <img
              alt="mudioo Logo"
              src={require("../Assets/hero10.jpg")}
              className="object-cover w-full rounded-lg"
            /> */}
            <Swiper
              // style={{
              //   "--swiper-pagination-color": configData.secondaryColor,
              //   "--swiper-pagination-bullet-inactive-color": "white",
              // }}
              direction={"vertical"}
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false, // Optional: keep autoplay running after user interactions
              }}
              className="mySwiper w-full h-full"
            >
              <SwiperSlide>
                <div className="w-full h-full bg-text rounded-lg overflow-hidden">
                  <img
                    alt="retailflow Logo"
                    src={require("../Assets/hero10.png")}
                    className="object-cover w-full rounded-lg"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="w-full h-full bg-text rounded-lg overflow-hidden">
                  <img
                    alt="retailflow Logo"
                    src={require("../Assets/hero14.jpg")}
                    className="object-cover w-full rounded-lg"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full bg-text rounded-lg overflow-hidden">
                  <img
                    alt="retailflow Logo"
                    src={require("../Assets/hero12.jpg")}
                    className="object-cover w-full rounded-lg"
                  />
                </div>
              </SwiperSlide>
            </Swiper>

            <div className="absolute top-0 left-0 w-full h-full rounded-lg opacity-40 bg-black z-40" />
            <div className="absolute z-40 right-5 bottom-5 lg:bottom-5">
              <HeroHint
                loading={loading}
                text={t("personalAdvie")}
                icon={<UserCircleIcon />}
                index={0}
                windowWidth={screenSize.width}
              />
              <HeroHint
                loading={loading}
                text={t("boostConversion")}
                icon={<RocketLaunchIcon />}
                index={1}
                windowWidth={screenSize.width}
              />
              <HeroHint
                loading={loading}
                text={t("supportTeam2")}
                icon={<LifebuoyIcon />}
                index={2}
                windowWidth={screenSize.width}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

const HeroHint = ({ text, icon, loading, index, windowWidth }) => {
  const slideHeroimage = {
    offscreen: {
      x: 150 * index + 50,
    },
    onscreen: {
      x: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const SlideHintsMObile = {
    offscreen: {
      y: 200 + index * 50,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      initial={"offscreen"}
      whileInView={!loading && "onscreen"}
      viewport={{ once: true, amount: 0.1 }}
      variants={windowWidth < 980 ? SlideHintsMObile : slideHeroimage}
      class="backdrop-blur-sm  mb-2 rounded-lg p-3 lg:p-4 lg:py-3 py-2  flex items-center bg-white/80  "
    >
      <div className="w-11 h-11 shrink-0 mr-4 rounded-full bg-primary flex  items-center justify-center">
        <div className="lg:w-6 lg:h-6 w-4 h-4 text-white">{icon}</div>
      </div>
      <div className="text-text lg:text-base text-sm leading-6 font-bold">
        {text}
      </div>
    </motion.div>
  );
};

export default Hero;
