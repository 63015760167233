import React from "react";
import { useTranslation } from "react-i18next";

function Advantages2() {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white lg:border-t border-border lg:px-10 px-5 lg:pb-10 flex justify-center">
      <div className="w-full flex flex-col items-center pt-5 lg:pt-20 pb-12 max-w-6xl">
        <h2 className="text-3xl text-text lg:text-center leading-[44px] w-full font-bold">
          {t("advantagesTitle")}
        </h2>

        <div className="text-darkgray lg:text-center max-w-3xl text-lg mt-4">
          {t("holisticConcept")}
        </div>

        <div className="w-full flex flex-col lg:flex-row mt-10 lg:mt-16">
          <div className="w-full bg-lightgray  lg:w-1/2 lg:p-10 p-7 rounded-lg mr-4">
            <h2 className="text-2xl text-text mb-10 font-bold">
              {t("customerBenefitsTitle")}
            </h2>
            <CheckItem text={t("customerBenefit1")} />
            <CheckItem text={t("customerBenefit2")} />
            <CheckItem text={t("customerBenefit3")} />
            <CheckItem text={t("customerBenefit4")} />
            <CheckItem text={t("customerBenefit5")} />
          </div>

          <div className="w-full bg-lightgray lg:w-1/2 lg:p-10 p-7 mt-5 lg:mt-0 rounded-lg lg:ml-4">
            <h2 className="text-2xl text-text mb-10 font-bold">
              {t("retailerBenefitsTitle")}
            </h2>

            <CheckItem text={t("retailerBenefit1")} />
            <CheckItem text={t("retailerBenefit2")} />
            <CheckItem text={t("retailerBenefit3")} />
            <CheckItem text={t("retailerBenefit4")} />
            <CheckItem text={t("retailerBenefit5")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages2;

function CheckItem({ text }) {
  return (
    <div className="flex mt-5">
      <div className="text-white shrink-0 rounded-full h-6 w-6 bg-secondary border-2 border-secondary flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 12.75 6 6 9-13.5"
          />
        </svg>
      </div>
      <div className="ml-3 font-semibold text-text">{text}</div>
    </div>
  );
}
