import { motion, useScroll, useTransform } from "framer-motion";
import { isMobile } from "react-device-detect";
import { ReactComponent as PNPLogo } from "../Assets/pnp-logo.svg";

function Supporters() {
  // const { scrollY } = useScroll();
  // const translateLogoOne = useTransform(scrollY, [2000, 2500], [1000, 0]);
  // const translateLogoTwo = useTransform(scrollY, [2050, 2550], [1000, 0]);
  // const translateLogoThree = useTransform(scrollY, [2100, 2600], [1000, 0]);

  // const scrollInRightFirst = useTransform(scrollY, [1800, 2200], [300, 0]);
  // const scrollInRightSecond = useTransform(scrollY, [1900, 2300], [300, 0]);
  // const scrollInRightThird = useTransform(scrollY, [2000, 2340], [300, 0]);

  const openPdf = () => {
    // Replace with your file path
    const pdfUrl = "/LPW-SH-Plakat-A3-RZ.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="w-full  flex bg-white border-white border-b border-t mb-12 lg:mb-32 mt-28 lg:mt-40 lg:mt-8 flex-col items-center">
      <div className="max-w-6xl -mt-20 lg:mt-8 flex items-center gap-10 flex-col lg:grid px-5 lg:grid-cols-4 grid-cols-1 ipad:px-0 flexitems-center w-full  ">
        <div className="w-full  lg:text-start text-center">
          <h2 className="text-3xl font-bold">Gefördert durch:</h2>
        </div>
        <div className="lg:w-full w-44 lg:px-10">
          <img
            alt="WTSH Logo"
            className=""
            src={require("../Assets/wtshlogo.png")}
          />
        </div>

        <div
          onClick={openPdf}
          className="lg:w-full w-72 cursor-pointer lg:ml-10"
        >
          <img
            alt="eu logo"
            src={require("../Assets/sh_de_rgb_bp_EU_KOFINANZIERT_LANDESREGIERUNG.png")}
          />
        </div>

        <div className="lg:w-full w-60 lg:pl-20">
          <PNPLogo />
        </div>
      </div>
    </div>
  );
}

export default Supporters;
