import React from "react";
import Menubar from "../Components/Menubar";

function Imprint() {
  return (
    <div className="overflow-hidden ">
      <Menubar />

      <div className="bg-gradient-to-b from-[#FCFBFF] py-36 px-5 lg:px-0 to-[#FCFBFF] flex justify-center w-full ">
        <div className="h-32" />
        <div className="w-full max-w-5xl">
          <h1 className="font-bold text-3xl lg:text-5xl max-w-4xl lg:mt-10 text-text leading-[40px]  lg:leading-[65px]">
            Impressum
          </h1>
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            Angaben gemäß § 5 TMG
          </h2>
          <div>
            Nordflow Software GmbH
            <br />
            Schauenburgerstraße 116,
            <br />
            24118 Kiel
            <br />
            <br />
            <span className="font-bold">
              Vertretungsberechtigte Geschäftsführer: <br />
            </span>
            Max Löhrer, Christoph von Trotha
            <br />
          </div>
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl text-text leading-[40px]  lg:leading-[65px]">
            Kontakt
          </h2>
          015789277139
          <br />
          <a className="text-primary" href="mailto:hallo@mudioo.de">
            christoph.v.trotha@retailflow.app
          </a>
          <br />
          <a className="text-primary" href="https://mudioo.de">
            www.retailflow.app
          </a>
          <br />
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl text-text leading-[40px]  lg:leading-[65px]">
            Angaben zum Handelsregistereintrag:
          </h2>
          Registergericht: Amtsgericht Kiel
          <br />
          Registernummer: HRB 25644 KI
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl text-text leading-[40px] mt-2 lg:leading-[45px]">
            Verbraucherstreitbeilegung/
            <br />
            Universalschlichtungsstelle
          </h2>
          <br />
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          <br />
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
          <br />
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl text-text leading-[40px]  lg:leading-[65px]">
            Umsatzsteuer-Identifikationsnummer:
          </h2>
          DE 361273578
        </div>
      </div>
    </div>
  );
}

export default Imprint;
