import React from "react";
import { useTranslation } from "react-i18next";

function GetBetaUser() {
  const { t } = useTranslation();

  return (
    <div className="flex bg-primary ipad:px-0 px-5 justify-center w-full">
      <div className="w-full  bg-primary overflow-hidden rounded-2xl relative p-6 pt-8 pb-0 lg:p-3 lg:pt-16 flex-col items-center justify-center flex">
        <h2 className="lg:text-4xl text-center text-2xl z-20 text-white font-extrabold lg:leading-[60px]">
          {t("bookInfoCall")}
        </h2>
        <p className="text-base lg:text-lg lg:max-w-2xl relative text-lightgreen text-center mt-4 font-semibold z-30">
          {t("infoCallDescription")}
        </p>
        <a
          target="_blank"
          href="https://calendly.com/christoph-v-trotha/-mudioo"
          className="bg-text cursor-pointer font-bold h-14 z-20 w-4/5 lg:w-60 rounded-full lg:ml-3 lg:mt-10 mt-8 flex items-center justify-center text-white"
        >
          {t("bookInfoCall")}
        </a>

        <div className="mt-5 lg:mt-8 w-full lg:w-2/3 lg:items-center">
          <div className="flex lg:flex-row mb-5 flex-col">
            <div className="bg-secondary absolute -top-[470px] lg:-top-96 z-10 -left-56 lg:-left-36 w-[500px] h-[500px] rounded-full" />
            <div className="bg-secondary absolute top-36 lg:top-56 -right-44 w-96 h-96 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetBetaUser;
