import { motion, useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as RFLogo } from "../Assets/logo_text.svg";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";

import "../App.css";
import { GlobeAltIcon, LanguageIcon } from "@heroicons/react/24/outline";

function Menubar({ selected }) {
  const { pathname } = useLocation();
  const [menuBg, setMenuBg] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { scrollY } = useScroll();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // if (window.location.pathname !== "/" && window.location.pathname !== "") {
    //   setMenuBg(true);
    // }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    return scrollY.onChange((v) => {
      if (scrollY.current > 50) {
        setMenuBg(true);
      } else {
        // if (window.location.pathname === "/") {
        //   setMenuBg(false);
        // }
        setMenuBg(false);
      }
    });
  }, [scrollY]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Sprache im localStorage speichern
  };

  return (
    <div className="relative z-50">
      <motion.div
        className={`w-full h-20 top-0 fixed z-50 transition-all border-border px-5 lg:px-10 flex justify-center  items-center ${
          menuBg
            ? "bg-white/95 backdrop-blur-lg  pt-0 lg:border-b border-b"
            : "lg:bg-transparent bg-white  lg:pt-10 pt-0 lg:border-none border-b"
        } ${showMobileMenu ? "bg-white" : ""} `}
      >
        <div className="w-full flex items-center h-full justify-between max-w-6xl">
          <div className="flex items-center">
            <a
              href="/"
              className="flex flex-row  items-center text-xl text-primary"
            >
              <div className="lg:w-32 w-28  mt-0.5">
                <RFLogo />
              </div>
              {/* <div
                className={`text-2xl font-800 text-primary font-extrabold  ml-2 font-nunito`}
              >
                retail<span className="text-text">flow</span>
              </div> */}
            </a>
            {/* <div className={`font-bold ml-20 text-center `}>
              <Link
                className={`mx-4 ${
                  window.location.pathname === "/"
                    ? "text-primary"
                    : "text-dark"
                }`}
                to="/"
              >
                START
              </Link>
              <a
                className={`mx-8 ${
                  window.location.pathname === "/aboutus"
                    ? "text-primary"
                    : "text-dark"
                }`}
                href="/aboutus"
              >
                ÜBER UNS
              </a>
              <a
                className={`mx-4 ${
                  window.location.pathname === "/contact"
                    ? "text-primary"
                    : "text-dark"
                }`}
                href="/contact"
              >
                KONTAKT
              </a>
            </div> */}
          </div>
          <div className="w-1/3 hidden lg:flex justify-end items-center">
            <a
              href="/"
              className={`${
                selected === "home"
                  ? "text-primary underline"
                  : "text-text hover:text-secondary"
              }  font-bold mr-8 `}
            >
              {t("overview")}
            </a>

            <a
              href="/contact"
              className={`${
                selected === "contact"
                  ? "text-primary underline"
                  : "text-text hover:text-secondary"
              }  font-bold mr-8 `}
            >
              {t("contactMenu")}
            </a>

            <div className="mr-12 ml-10">
              <Dropdown
                // label={i18n.language === "de" ? "Deutsch" : "Englisch"}
                label={
                  <div className="flex items-center text-dark text-base font-semibold">
                    <LanguageIcon
                      strokeWidth={2}
                      className="w-5 mr-1 text-dark"
                    />
                    {i18n.language === "de" ? "Deutsch" : "Englisch"}
                  </div>
                }
                inline
                dismissOnClick={true}
              >
                <Dropdown.Item
                  onClick={() => {
                    changeLanguage("de");
                  }}
                >
                  <div className="px-3">Deutsch</div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  <div className="px-3">Englisch</div>
                </Dropdown.Item>
              </Dropdown>
            </div>
            {/* 
            <a
              className={`rounded-lg ml-8  shrink-0 active:bg-active hover:bg-primary hover:text-white transition-all px-6 py-2.5 text-active text-sm font-semibold bg-lightgreen`}
              href="/contact"
            >
              Kontakt
            </a> */}
            <a
              className={`rounded-lg ml-3 shrink-0 active:bg-active hover:bg-secondary transition-all px-6 py-2.5 text-white  text-sm font-semibold bg-primary`}
              href="https://retailflow-cms.vercel.app/"
              target="_blank"
            >
              {t("login")}
            </a>
          </div>

          <div className="items-center justify-end lg:hidden flex ">
            <div className="mr-5">
              <Dropdown
                // label={i18n.language === "de" ? "Deutsch" : "Englisch"}
                label={
                  <div className="flex items-center text-dark text-base font-semibold">
                    <LanguageIcon
                      strokeWidth={2}
                      className="w-5 mr-1 text-dark"
                    />
                    {i18n.language === "de" ? "DE" : "EN"}
                  </div>
                }
                inline
                dismissOnClick={true}
              >
                <Dropdown.Item
                  onClick={() => {
                    changeLanguage("de");
                  }}
                >
                  Deutsch
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  Englisch
                </Dropdown.Item>
              </Dropdown>
            </div>

            <div
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className={`flex justify-center lg:hidden items-center rounded-full transition-all h-16 aspect-square ${
                showMobileMenu ? "rotate-45" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 9h16.5m-16.5 6.75h16.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </motion.div>

      <div
        className={`h-screen w-full bg-white z-60 fixed left-0 flex flex-col items-center  transition-all ${
          showMobileMenu ? "flex opacity-1" : "hidden opacity-0"
        }`}
      >
        <div className="h-28 border-b border-border w-full" />
        <a
          className={`text-lg w-full px-5 py-5 font-bold border-b border-border ${
            selected === "home" ? "text-primary" : "text-text "
          }`}
          href="/"
        >
          {t("overview")}
        </a>
        {/* <a
          className={`text-lg w-full px-5 py-5 font-bold border-b border-border ${
            selected === "pricing" ? "text-primary" : "text-text "
          }`}
          href="/pricing"
        >
          Preise
        </a> */}
        <a
          className={`text-lg w-full px-5 py-5 font-bold border-b border-border ${
            selected === "contact" ? "text-primary" : "text-text "
          }`}
          href="/contact"
        >
          {t("contactMenu")}
        </a>
        <a
          className=" text-lg w-full px-5 py-5 font-bold border-b border-border"
          href="https://mudioo-cms-business.vercel.app/#/"
          target="_blank"
        >
          {t("login")}
        </a>
      </div>
    </div>
  );
}

export default Menubar;
