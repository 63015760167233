import React from "react";
import { useTranslation } from "react-i18next";
import {
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CurrencyEuroIcon,
  GlobeAltIcon,
  RocketLaunchIcon,
  ShoppingCartIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

function Problem() {
  const { t } = useTranslation();

  return (
    <div className="bg-white lg:border-t border-border justify-center lg:px-10 px-5 pb-16  w-full  relative flex ">
      <div className="max-w-6xl pt-10 flex items-start w-full flex-col justify-between">
        <div className="w-full grid grid-cols-1 lg:grid-cols-4 mt-4 gap-0 lg:gap-5">
          <PItem
            icon={<ChatBubbleLeftEllipsisIcon strokeWidth={2} />}
            text={t("consultQuickly")}
          />

          <PItem
            icon={<UserGroupIcon strokeWidth={2} />}
            text={t("supportTeam")}
          />
          <PItem
            icon={<ShoppingCartIcon strokeWidth={2} />}
            text={t("drivePurchase")}
          />
          <PItem
            icon={<RocketLaunchIcon strokeWidth={2} />}
            text={t("boostConversion")}
          />
        </div>
      </div>
    </div>
  );
}

const PItem = ({ text, icon }) => {
  return (
    <div className="w-full flex items-center lg:flex-col lg:text-center text-start text-text font-semibold text-lg lg:mb-0 mb-3  lg:h-64  bg-lightgray lg:bg-white rounded-lg  p-5">
      <div className="bg-gradient-to-tr  from-secondary to-primary shrink-0 mr-3 lg:mr-0 lg:w-20 lg:h-20 w-12 h-12 rounded-full lg:rounded-full flex items-center justify-center lg:mb-5">
        <div className="lg:w-7 w-5 text-lightgreen">{icon}</div>
      </div>
      <div className="px-5">{text}</div>
    </div>
  );
};

export default Problem;
