export const translation = {
  en: {
    translation: {
      overview: "Overview",
      login: "Login",
      contactMenu: "Contact",
      der: "AI-powered digital",
      digital: "customer advice",
      salesAssistent: "in retail stores",
      heroSubtitle:
        "With retailflow, you advise every customer individually and in real-time – while relieving your sales team, increasing the conversion rate, and making customers happy.",
      bookCall: "Book Info Call",
      AISupport: "AI Support",
      rf_helps: "retailflow helps you to:",
      consultQuickly: "Personalized Advice",
      personalAdvie: "Personalized Advice",
      supportTeam: "Support and relieve sales team",
      supportTeam2: "Support sales team",
      drivePurchase: "Enchance customer experience",
      avoidOnline: "Avoid migration to online retail",
      boostConversion: "Boost conversion",
      preventWait: "Prevent customer wait times for consultation",
      aiChatHeading: "Mit unserem KI-Chat Kunden individuell beraten",
      howItWorksTitle: "How it works:",
      howItWorksDescription:
        "Machen Sie Ihren Online-Shop noch erfolgreicher – mit unserem innovativen Chatbot speziell für Einzelhändler. Bieten Sie Ihren Kunden ein modernes Einkaufserlebnis und heben Sie sich von der Konkurrenz ab!",
      step1: "Step 1",
      step2: "Step 2",
      step3: "Step 3",
      productsCreation: "Create products",
      qrCodes: "QR-Codes",
      consulting: "Consult customers",
      step1Description:
        "Enter complex products into the CMS and input all product information. Various AI's help with content creation.",
      step2Description:
        "Place the automatically generated QR codes next to the products in the store.",
      step3Description:
        "Inform, consult & drive customers to make purchasing decisions using the automatically generated web app.",
      allFeatures: "All features of retailflow",
      oneView: "",
      corporateDesign: "Custom Design",
      multimediaPresentation: "Multimedia Presentation",
      aiContentCreation: "Data Preparation",
      chatbot: "AI-Chat",
      productComparison: "Product Comparison",
      liveTrackingFeedback: "Analytics",
      corporateDesignText:
        "We create a web app in your individual brand design.",
      multimediaPresentationText:
        "Product data can be easily integrated from your own database or POS system.",
      aiContentCreationText:
        "Our AI crawler fills in missing product information.",
      chatbotText:
        "Our AI chat answers all product-specific questions and helps customers find the perfect product.",
      productComparisonText: "Customers can compare different products.",
      liveTrackingFeedbackText:
        " Understand customer behavior better through various data analyses.",
      advantagesTitle: "Advantages of retailflow",
      holisticConcept:
        "A holistic concept that improves the sales process in brick-and-mortar retail.",
      customerBenefitsTitle: "Benefits for Customers",
      retailerBenefitsTitle: "Benefits for Retailers",
      customerBenefit1: "Modern AI consultation",
      customerBenefit2: "Direct product comparison",
      customerBenefit3: "All information in one place ",
      customerBenefit4: "Consultation without waiting times",
      customerBenefit5: "Fast purchasing decisions",
      retailerBenefit1: "Increased revenue through a higher conversion rate",
      retailerBenefit2: "Relief for the sales team",
      retailerBenefit3: "Easy integration with databases or POS systems",
      retailerBenefit4: "WebApp in custom brand design",
      retailerBenefit5: "Continuous updates and support",
      audioguideTitle: "'I'm just looking around'",
      audioguideDescription:
        "Is this statement familiar to you? Simply inform customers about the products with the audio function and drive them to make purchasing decisions. With the text-to-speech AI in the CMS, you can easily create audio guides that customers can access via QR code on the web app. Feel free to listen to how the audio guides could sound for your products:",
      playText: "Play",
      customerAdvisor: "Digital Advisor Niander",
      bikeName: "Bicycle XY",
      metricsConversionRate: "Increase conversion rate by up to 20%.",
      metricsLearningTime:
        "Employees need an average of 34 minutes to learn how to work with retailflow.",
      metricsCostSaving:
        "With retailflow, you save up to 90% of costs compared to custom developments.",
      upToTwentyPercent: "Up to 20%",
      minutes: "34 minutes",
      upToNintyPercent: "Up to 90%",
      howToStartTitle: "How to start:",
      stepsIntroText:
        "The following four steps show how retailflow can be implemented at your store:",
      step1Heading: "Get to know",
      step1Details:
        "In a 20-minute online meeting, the features and applications of retailflow are demonstrated and all open questions are answered.",
      step2Heading: "Try out",
      step2Details:
        "We activate a test access. In another meeting, we will create 4-6 test products together, after which you can continue testing independently.",
      step3Heading: "Decide",
      step3Details:
        "Decide on the appropriate pricing model and close the usage contract.",
      step4Heading: "Apply",
      step4Details:
        "The software can now be used independently and comprehensively, and all employees are trained. The retailflow team is available for any questions.",
      scheduleMeetingText: "Schedule an appointment now",

      chatSectionHeading: "Individual Customer advice via AI Chat",
      chatSectionText:
        "Offer your customers the shopping experience of the future. Our smart AI chat supports your customers directly in-store. They can ask questions, compare products, receive recommendations, and make well-informed purchasing decisions.",
      chatSectionAdvantage1: "24/7 customer service",
      chatSectionAdvantage2: "Targeted product search",
      chatSectionAdvantage3:
        "Individual consultation, comparison, and decision-making",

      pricingTitle: "What does retailflow cost?",
      pricingDescriptionPt1:
        "retailflow can be used on an annual or monthly subscription.",
      pricingDescriptionPt2: "The prices apply per",
      pricingDescriptionPt3: "application location",
      annualSubscription: "Annual subscription",
      monthlySubscription: "Monthly subscription",
      freeTrial: "Try for free",
      basicVersion: "Basic version",
      professionalVersion: "Professional version",
      enterprise: "Enterprise",
      or: "or",
      perMonth: "/ month",
      perYear: "/ year",
      includes: "Includes",
      optionsAndMore: "Options & more",
      contactUs: "Contact us",
      additionalInfo: "*All prices plus 19% VAT.",
      contactNow: "Contact us now",
      contactNowSubtitle:
        "We are pleased about your interest in mudioo. Do you have any questions, comments, or other concerns? Feel free to send us a message. We are happy to help and will get back to you as soon as possible.",
      includesProducts: "Includes 40 products",
      includesHunderedProducts: "Includes 100 products",
      feedbackfunction: "Feedback function",
      allBasic: "All Basic functions",
      ciModularStructure: "CI & modular structure",
      multimediaPresentation: "Data Integration",
      aiContentCreationBasic: "AI content creation (Basic)",
      productComp: "Product comparison",
      aiContentCreationPro: "AI content creation (Pro)",
      supporters: "Supporters",
      bookInfoCall: "Book info call now",
      infoCallDescription:
        "Interested in retailflow or have questions about the offer? Simply book a non-binding introductory meeting.",
      faqTitle: "Frequently Asked Questions",
      tryRetailflow: "Want to try retailflow for free?",
      infoCallDescription:
        "Book a non-binding info call now. We are happy to activate a free test account for you.",

      faq: [
        {
          question: "Where do I get the QR codes?",
          answer:
            "Once you create a product in the CMS, a QR code is automatically generated. You can easily place this on the respective product.",
        },
        {
          question: "Can I change the QR code content later?",
          answer:
            "Yes! You always have the option to adjust the content of the individual QR codes through the CMS.",
        },
        {
          question: "Do I need to create a domain for the web app?",
          answer:
            "No! We automatically create a suitable domain for you, through which your web app is always accessible even without a QR code. You can also choose your own domain.",
        },
        {
          question:
            "How long does it take for changes to go live on the web app?",
          answer:
            "All content you create or adjust in the CMS is available in real-time in the web app as soon as you release it.",
        },
        {
          question: "What if I have little capacity to create product content?",
          answer:
            "AI content creation significantly simplifies content creation. You just need to bring in your expertise.",
        },
        {
          question:
            "How do I ensure the chatbot does not give incorrect information?",
          answer:
            "All facts the chatbot refers to come from the product information you create. Thus, you have full control over the accuracy of the chatbot's statements.",
        },
        {
          question: "How does the product comparison work?",
          answer:
            "Different products of the same type can be compared by your customers while standing in front of the products. Various product-specific parameters such as physical performance, specific features, or prices are compared.",
        },
        {
          question: "What options does live tracking offer me?",
          answer:
            "With the live tracking function, you can observe customer behavior in real-time. For example, you can see if customers have been lingering at certain products for a long time. Then you can approach them and start a personal sales conversation. Additionally, you can evaluate customer behavior afterward to draw conclusions about potential improvement areas on your sales floor.",
        },
      ],
      contact: {
        title: "Do you have any questions?",
        firstNamePlaceholder: "First name*",
        lastNamePlaceholder: "Last name*",
        phonePlaceholder: "Phone",
        organisationPlaceholder: "Organization name",
        emailPlaceholder: "Email*",
        questionPlaceholder: "Your concern*",
        privacyPolicyText: "I have read the",
        privacyPolicyLink: "Privacy Policy",
        submitButton: "Submit",
        read: "",
        loadingText: "Loading...",
        requiredField: "Required field",
        successTitle: "Request sent",
        successMessage:
          "Your request has been successfully sent. We will get back to you as soon as possible.",
        successButton: "All right",
      },
      errors: {
        emailInvalid: "Please enter a valid email address.",
        nameRequired: "Please enter your first name.",
        lastNameRequired: "Please enter your last name.",
        questionMinLength:
          "Please describe your concern with at least 6 characters.",
        acceptPrivacyPolicy: "Please accept the privacy policy.",
        formSubmitError:
          "An error occurred. Please try again or contact us via email.",
      },
      footer: {
        description: "The shopping experience of the future",
        pages: "Pages",
        home: "Home",
        requestDemo: "Request Demo",
        legal: "Legal",
        privacyPolicy: "Privacy Policy",
        imprint: "Imprint",
        contact: "Contact",
        phone: "+49 157 89277139",
        email: "Send email",
        copyright: "Copyright © 2025 retailflow. All rights reserved.",
      },
    },
  },
  de: {
    translation: {
      overview: "Übersicht",
      login: "Login",
      contactMenu: "Kontakt",
      der: "KI-Basierte ",
      digital: "Kundenberatung",
      salesAssistent: "im Einzelhandel",
      heroSubtitle:
        "Mit retailflow berätst du jeden Kunden individuell und in Echtzeit – gleichzeitig entlastest du dein Team, steigerst die Conversion Rate und machst Kunden glücklich.",
      bookCall: "Infogespräch buchen",
      AISupport: "Support sales team",
      rf_helps: "Die Vorteile von Retailflow:",
      consultQuickly: "Kunden individuell beraten",
      supportTeam: "Verkaufspersonal unterstützen",
      supportTeam2: "Verkaufsteam entlasten",
      drivePurchase: "Kundenerlebnis verbessern",
      avoidOnline: "Abwanderung zum Online-Handel vermeiden",
      boostConversion: "Conversion steigern",
      preventWait: "Wartezeiten des Kunden bis zur Beratung verhindern",
      howItWorksTitle: "So funktioniert’s:",
      howItWorksDescription:
        "Die retailflow-Software besteht aus einem Content-Management-System (CMS) und einer Web-App. Aus den in das CMS eingegebenen Inhalten wird automatisch eine Web-App für Kunden generiert.",
      step1: "1. Schritt",
      step2: "2. Schritt",
      step3: "3. Schritt",
      productsCreation: "Produkte anlegen",
      qrCodes: "QR-Codes",
      consulting: "Kunden beraten",
      step1Description:
        "Erklärungsbedürftige Produkte im CMS anlegen und alle Produktinformationen eingeben. Verschiedene KI’s helfen bei der Inhaltserstellung.",
      step2Description:
        "Die durch das System automatisch generierten QR-Codes an den Produkten auf der Ladenfläche anbringen.",
      step3Description:
        "Kunden mit der automatisch generierten Web-App informieren, beraten & zur Kaufentscheidung bewegen.",
      allFeatures: " Alle Features von retailflow",
      oneView: "auf einen Blick",
      corporateDesign: "Individuelles Design",
      multimediaPresentation: "Multimediale Darstellung",
      aiContentCreation: "Datenaufbereitung",
      chatbot: "Chatbot",
      read: "gelesen.",
      chatSectionHeading: "Kunden per KI-Chat individuell beraten",
      chatSectionText:
        "Ermögliche deinen Kunden das Einkaufserlebnis der Zukunft. Unser smarter KI-Chat unterstützt deine Kunden direkt im Store. Sie können Fragen stellen, Produkte vergleichen, Empfehlungen erhalten und eine gute Kaufentscheidung treffen.",
      chatSectionAdvantage1: "Gezielte Produktsuche",
      chatSectionAdvantage2: "Individuell beraten, vergleichen und entscheiden",
      chatSectionAdvantage3: "Kundenservice 24/7",
      personalAdvie: "Individuelle Beratung",
      productComparison: "Produktvergleich",
      liveTrackingFeedback: "Analytics",
      corporateDesignText:
        "Wir erstellen eine Web-App im individuellen Brand-Design.",
      multimediaPresentationText:
        "Produktdaten können aus der eigenen Datenbank oder POS-System einfach integriert werden.",
      aiContentCreationText:
        "Unser KI-Crawler ergänzt fehlende Produktinformationen.",
      chatbotText:
        "Unser KI-Chat beantwortet alle produktspezifischen Fragen des Kunden und berät ihn dabei, das perfekte Produkt zu finden",
      productComparisonText: "Kunden könne verschiedene Produkte vergleichen.",
      liveTrackingFeedbackText:
        "Das Kundenverhalten durch verschiedene Datenanalyse besser verstehen.",
      advantagesTitle: "Vorteile von retailflow",
      holisticConcept:
        "Ein ganzheitliches Konzept, das den Verkaufsprozess im stationären Einzelhandel verbessert.",
      customerBenefitsTitle: "Vorteile für Kundinnen & Kunden",
      retailerBenefitsTitle: "Vorteile für Einzelhändler",
      customerBenefit1: "Moderne KI-Beratung",
      customerBenefit2: "Direkter Produktvergleich",
      customerBenefit3: "Alle Informationen auf einen Blick",
      customerBenefit4: "Direkte Übersicht über Produkte und deren Vorteile",
      customerBenefit5: "Beratung ohne Wartezeit",
      retailerBenefit1: "Mehr Umsatz durch höhere Conversion-Rate",
      retailerBenefit2: "Entlastung des Vertriebsteams",
      retailerBenefit3: "Anbindung an Datenbank oder POS-System",
      retailerBenefit4: "WebApp im eigenen Brand-Design",
      retailerBenefit5: "Updates & Support",
      audioguideTitle: "“Ich schaue mich nur mal um”",
      audioguideDescription:
        "Ist Ihnen diese Aussage bekannt? Kundinnen & Kunden einfach mit der Audiofunktion über die Produkte aufklären und zur Kaufentscheidung bewegen. Dazu können über das CMS mit einer Text-zu-Sprache-KI einfach Audioguides erstellt werden, die Kunden mittels QR-Code über die Web-App abrufen können. Hören Sie gerne einmal rein, wie die Audioguides für Ihre Produkte klingen könnten:",
      playText: "Abspielen",
      customerAdvisor: "Kundenberater Niander",
      bikeName: "Fahrrad XY",
      metricsConversionRate: "Erhöhung der Conversion-Rate um bis zu 20%.",
      metricsLearningTime:
        "Mitarbeitende benötigen ∅ 34 Minuten, um das Arbeiten mit retailflow zu erlernen.",
      metricsCostSaving:
        "Mit retailflow spart man bis zu 90% der Kosten im Vergleich zu Eigenentwicklungen.",
      upToTwentyPercent: "Bis zu 20%",
      minutes: "34 Minuten",
      upToNintyPercent: "Bis zu 90%",
      howToStartTitle: "So geht’s los:",
      stepsIntroText:
        "Die folgenden vier Schritte zeigen, wie retailflow bei Ihnen in die Anwendung kommt:",
      step1Heading: "Kennenlernen",
      step1Details:
        "In einem 20-minütigen Online-Meeting werden die Funktionen und Einsatzmöglichkeiten von retailflow gezeigt und alle offenen Fragen geklärt.",
      step2Heading: "Ausprobieren",
      step2Details:
        "Wir schalten einen Testzugang frei. In einem weiteren Meeting werden gemeinsam 4-6 Testinhalte angelegt und danach kann selbstständig weiter getestet werden.",
      step3Heading: "Entscheiden",
      step3Details:
        "Es wird entschieden, welches Preismodell passend ist und der Nutzungsvertrag wird geschlossen.",
      step4Heading: "Anwenden",
      step4Details:
        "Die Software kann nun eigenständig und vollumfänglich genutzt werden und alle Mitarbeitenden werden eingewiesen. Bei Fragen hilft das retailflow-Team.",
      scheduleMeetingText: "Jetzt Termin vereinbaren",

      pricingTitle: "Was kostet retailflow?",
      pricingDescriptionPt1:
        "retailflow kann im Jahresabo oder im Monatsabo genutzt werden.",
      pricingDescriptionPt2: "Die Preise gelten jeweils pro",
      pricingDescriptionPt3: "Anwendungsstandort",
      annualSubscription: "Jahresabo",
      monthlySubscription: "Monatsabo",
      freeTrial: "Kostenlos testen",
      basicVersion: "Basisversion",
      professionalVersion: "Professional Version",
      enterprise: "Enterprise",
      or: "oder",
      perMonth: "/ Monat",
      perYear: "/ Jahr",
      includes: "Inkl.",
      optionsAndMore: "Möglichkeiten &",
      contactUs: "Kontakt aufnehmen",
      additionalInfo: "*Alle Preise zuzüglich 19% MwSt.",
      contactNow: "Jetzt Kontakt aufnehmen",
      contactNowSubtitle:
        "Wir freuen uns über ihr Interesse an mudioo. Sie haben Fragen, Hinweise oder ein anderes Anliegen? Schreiben Sie uns gerne eine Nachricht. Wir sind gerne für Sie da und melden uns schnellstmöglich zurück.",
      includesProducts: "Inkl. 40 Produkte",
      includesHunderedProducts: "Inkl. 100 Produkte",
      feedbackfunction: "Feedbackfunktion",
      allBasic: "Alle Basic Funktionen",
      ciModularStructure: "CI & modulare Struktur",
      multimediaPresentation: "Datenintegration",
      aiContentCreationBasic: "KI-Contenterstellung (Basic)",
      productComp: "Produktvergleich",
      aiContentCreationPro: "KI-Contenterstellung (Pro)",
      supporters: "Unterstützer",
      bookInfoCall: "Infogespräch buchen",
      infoCallDescription:
        "Interesse an retailflow oder Fragen zum Angebot? Einfach ein unverbindliches Kennenlerngespräch buchen.",
      faqTitle: "Häufig gestellte Fragen",
      tryRetailflow: "Lust, retailflow kostenlos auszuprobieren?",
      infoCallDescription:
        "Jetzt ein unverbindliches Infogespräch buchen. Wir schalten Ihnen gerne einen kostenlosen Testaccount frei.",

      faq: [
        {
          question: "Woher bekomme ich die QR-Codes?",
          answer:
            "Sobald sie ein Produkt im CMS anlegen, wird automatisch ein QR-Code generiert. Diesen können Sie einfach an dem jeweiligen Produkt platzieren.",
        },
        {
          question: "Kann ich die Inhalte des QR-Codes nachträglich ändern?",
          answer:
            "Ja! Sie haben über das CMS immer die Möglichkeit, die Inhalte der einzelnen QR-Codes anzupassen.",
        },
        {
          question: "Muss ich eine Domain für die Web-App erstellen?",
          answer:
            "Nein! Wir erstellen Ihnen automatisch eine passende Domain, über die Ihre Web-App auch ohne QR-Code immer abrufbar ist. Sie können dabei auch eine eigene Domain aussuchen.",
        },
        {
          question:
            "Wie lange dauert es, bis Änderungen in der Web-App online sind?",
          answer:
            "Alle Inhalte, die Sie im CMS erstellen oder anpassen, sind in Echtzeit in der Web-App verfügbar, sobald Sie es freigeben.",
        },
        {
          question:
            "Was ist, wenn ich wenig Kapazitäten habe, um die Produktinhalte zu erstellen?",
          answer:
            "Durch die KI-Contenterstellung wird Ihnen die Erstellung der Inhalte deutlich erleichtert. Sie müssen lediglich Ihr Fachwissen einbringen.",
        },
        {
          question:
            "Wie gehe ich sicher, dass der Chatbot keine fehlerhaften Informationen weitergibt?",
          answer:
            "Alle Fakten, auf die sich der Chatbot bezieht, stammen aus den von Ihnen erstellten Produktinformationen. Damit haben Sie die volle Kontrolle auf die Richtigkeit der Aussagen des Chatbots.",
        },
        {
          question: "Wie funktioniert der Produktvergleich?",
          answer:
            "Verschiedene Produkte der gleichen Produktart können von Ihren Kunden, während sie vor den Produkten stehen, miteinander verglichen werden. Hier werden verschiedene produktspezifische Parameter wie z.B. physikalische Leistungen, bestimmte Features oder Preise gegenübergestellt.",
        },
        {
          question: "Welche Möglichkeiten bietet mir das Live-Tracking?",
          answer:
            "Mit der Live-Tracking-Funktion können Sie in Echtzeit das Kundenverhalten beobachten. So haben Sie beispielsweise die Möglichkeit zu sehen, dass Kunden sich schon länger bei bestimmten Produkten aufhalten. Dann können Sie zu Ihnen gehen und ein persönliches Verkaufsgespräch suchen. Weiterhin können Sie im Nachhinein das Kundenverhalten auswerten, um Rückschlüsse auf mögliche Verbesserungspotentiale auf Ihrer Verkaufsfläche zu ziehen.",
        },
      ],
      contact: {
        title: "Haben Sie noch Fragen?",
        firstNamePlaceholder: "Vorname*",
        lastNamePlaceholder: "Nachname*",
        phonePlaceholder: "Telefon",
        organisationPlaceholder: "Name der Organisation",
        emailPlaceholder: "E-Mail*",
        questionPlaceholder: "Ihr Anliegen*",
        privacyPolicyText: "Ich habe die",
        privacyPolicyLink: "Datenschutzerklärung",
        submitButton: "Absenden",
        loadingText: "Wird geladen...",
        requiredField: "Pflichtfeld",
        successTitle: "Anfrage Verschickt",
        successMessage:
          "Ihre Anfrage wurde erfolgreich gesendet. Wir melden uns schnellstmöglich bei Ihnen.",
        successButton: "Alles klar",
      },
      errors: {
        emailInvalid: "Bitte geben Sie eine gültige E-Mail-Adresse an.",
        nameRequired: "Bitte geben Sie Ihren Vornamen ein.",
        lastNameRequired: "Bitte geben Sie Ihren Nachnamen ein.",
        questionMinLength:
          "Bitte formulieren Sie Ihr Anliegen mit mindestens 6 Zeichen.",
        acceptPrivacyPolicy: "Bitte akzeptieren Sie die Datenschutzerklärung.",
        formSubmitError:
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns per E-Mail.",
      },
      footer: {
        description: "Das Einkaufserlebnis der Zukunft",
        pages: "Seiten",
        home: "Startseite",
        requestDemo: "Demo anfordern",
        legal: "Rechtliches",
        privacyPolicy: "Datenschutz",
        imprint: "Impressum",
        contact: "Kontakt",
        phone: "+49 157 89277139",
        email: "E-Mail schreiben",
        copyright: "Copyright © 2025 retailflow. Alle Rechte vorbehalten.",
      },
    },
  },
};
